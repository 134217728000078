.cardStyle{
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 32px 24px;
    width: 439px;
}

.cardType{
    padding-bottom: 46px;
    text-align: center;
    font-family: Roboto_Medium,sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    color: #1A1F6D;
    white-space: nowrap;
}

.cardAmount {
    padding-top: 46px;
    padding-bottom: 46px;
    text-align: center;
    border-bottom: 1px solid #8586A1;
    border-top: 1px solid #8586A1;
    margin-bottom: 25px;
    font-family: Roboto_Bold,sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 75px;
    color: #1A1F6D;
}

.cards{
    display:  flex;
    gap: 25px;
    justify-content: space-between;
    margin-bottom: 120px;
}

.radioLabel{
    display: flex;
    font-family: Roboto_Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #303030;
    margin-bottom: 19px;
}


.radioInputs{
    border-bottom: 1px solid #8586A1;
    margin-bottom: 32px;
}

.radioInput{
    margin-right: 15px;
    width: 24px;
    height: 24px;
}


@media only screen  and (max-width: 1440px) {
    .cards{
        flex-wrap: wrap;
        justify-content: space-evenly;
        margin-bottom: 50px;
    }
}

@media only screen  and (max-width: 768px) {

    .cardStyle{
        max-width: 439px;
        width: 100%;
        padding: 22px 18px;
    }

    .cardType{
        padding-bottom: 24px;
        font-size: 30px;
        line-height: 28px;
        white-space: break-spaces;
    }

    .cardAmount {
        padding-top: 22px;
        padding-bottom: 22px;
        font-size: 48px;
        line-height: 52px;
    }

    .radioLabel{
        font-size: 18px;
        line-height: 21px;
    }

    .radioInput{
        margin-right: 10px;
        width: 14px;
        height: 14px;
    }
}

