.subTitle{
    font-family: Roboto_Regular,sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #333663;
    margin-bottom: 60px;
}

.swiperDiv{
    padding-bottom: 50px;
    border-bottom: 1px solid #8586A1;
    margin-bottom: 48px;
}

.sliderInside{
    max-width: 544px;
    width: 100%;
    /*padding-left: 50px;*/
    /*padding-right: 50px;*/
    padding-bottom: 50px;
}

.imgDiv{
    max-width: 544px;
    width: 100%;
    height: 544px;
}

.imgImage {
    max-width: 544px;
    width: 100%;
    height: 544px;
}

.belowPart{
    margin-top: 32px;
    display: flex;
    align-items: center;
}

.name{
    margin-left: 5px;
    font-family: Roboto_Medium,sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #333663;
}
.checkBoxLabel{
    display: flex;
    font-family: Roboto_Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #303030;
    align-items: center;
}
.checkboxStyle{
    width: 32px;
    height: 32px;
}

.max_width{
    max-width: 1680px;
    width: 100%;
}
@media only screen and (min-width:1024px) and (max-width: 1440px) {
    .max_width {
        max-width: 1200px
    }
    .sliderInside{
        max-width: 400px;
        width: 100%;
        /*padding-left: 50px;*/
        /*padding-right: 50px;*/
        padding-bottom: 50px;
        margin: auto;
    }

    .imgDiv{
        max-width: 400px;
        width: 100%;
        height: 544px;
    }

    .imgImage {
        max-width: 400px;
        width: 100%;
        height: 544px;
    }
}

@media only screen and (min-width:768px)  and (max-width: 1024px) {
    .max_width {
        max-width: 900px
    }
    .sliderInside{
        max-width: 350px;
        width: 100%;
        /*padding-left: 50px;*/
        /*padding-right: 50px;*/
        padding-bottom: 50px;
        margin: auto;
    }

    .imgDiv{
        max-width: 350px;
        width: 100%;
        height: 544px;
    }

    .imgImage {
        max-width: 350px;
        width: 100%;
        height: 544px;
    }
}

@media only screen and (min-width:425px)  and (max-width: 768px) {
    .max_width{
        max-width:400px
    }
    .sliderInside{
        max-width: 300px;
        width: 100%;
        /*padding-left: 50px;*/
        /*padding-right: 50px;*/
        padding-bottom: 50px;
        margin: auto;
    }

    .imgDiv{
        max-width: 300px;
        width: 100%;
        height: 544px;
    }

    .imgImage {
        max-width: 300px;
        width: 100%;
        height: 544px;
    }

}

@media only screen  and (max-width: 425px) {
    .max_width{
        max-width:280px
    }
    .sliderInside{
        max-width: 300px;
        width: 100%;
        /*padding-left: 50px;*/
        /*padding-right: 50px;*/
        padding-bottom: 50px;
        margin: auto;
    }

    .imgDiv{
        max-width: 300px;
        width: 100%;
        height: 544px;
    }

    .imgImage {
        max-width: 300px;
        width: 100%;
        height: 544px;
    }
}

@media only screen  and (max-width: 320px) {
    .max_width{
        max-width:280px
    }
    .sliderInside{
        max-width: 300px;
        width: 100%;
        /*padding-left: 50px;*/
        /*padding-right: 50px;*/
        padding-bottom: 50px;
        margin: auto;
    }

    .imgDiv{
        max-width: 300px;
        width: 100%;
        height: 544px;
    }

    .imgImage {
        max-width: 300px;
        width: 100%;
        height: 544px;
    }
}





