.readTermsWhole{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 233px;
    border-bottom:1px solid #8586A1;
    max-width: 1680px;
    width: 100%;
    margin-bottom: 48px;
}

.subTitle{
    font-family: Roboto-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #333663;
    margin-bottom: 212px;
}

.chooseTerms{
    display: flex;
    align-items: center;
}

.checkboxLabelTerms{
    display: flex;
    font-family: Roboto_Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #1A1F6D;
    align-items: center;
}

.checkboxStyleTerms{
    width: 32px;
    height: 32px;
    margin-right: 8px;
}

@media only screen  and (max-width: 768px) {
    .readTermsWhole{
        padding-bottom: 70px;
    }
    .subTitle{
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 60px;
        text-align: center;
    }

    .checkboxLabelTerms{
        font-size: 22px;
        line-height: 26px;
        text-align: center;
    }
}

