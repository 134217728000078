.subTitle{
    margin-bottom: 32px;
    font-family: Roboto_Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #333663;
}


.allowed{
    margin-bottom: 39px;
    font-family: Roboto_Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #8586A1;
}

.uploadWhole{
    border-bottom:1px solid #8586A1;
    margin-bottom: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1680px;
    width: 100%;
}

.uploadButton{
    font-size: 48px;
    background-color: #FFFFFF;
    border: none;
    cursor: pointer;
    margin: auto;
}


@media only screen  and (max-width: 768px) {
    .subTitle{
        font-size: 24px;
        line-height: 30px;
        margin-top: 30px;
    }

    .allowed{
        font-size: 18px;
        line-height: 27px;
    }
}




