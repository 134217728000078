.title{
    font-family: Roboto_Bold,sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
    color: #1A1F6D;
    margin-bottom: 32px;
    text-align: center;
    margin-top: 200px;
}

.text{
    max-width: 1396px;
    width: 100%;
    margin: 0 auto 48px;
    font-family: Roboto_Regular,sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #333663;
}

@media only screen  and (max-width: 768px) {
    .title{
        font-size: 34px;
        line-height: 51px;
        margin-bottom: 12px;
        margin-top: 100px;
    }

    .text{
        width: 100%;
        margin: 0 auto 24px;
        font-size:18px;
        line-height:28px;
    }
}


