.btnStyle{
    background: #1A1F6D;
    border-radius: 4px;
    padding-top: 12px;
    padding-bottom: 12px;
    border: none;
    cursor: pointer;
    font-family: Roboto_Medium, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    text-align: center;
    max-width: 402px;
    width: 100%;
}
.btnStyle:disabled{
    cursor: not-allowed;
    color:grey
}

.btnStyle:hover{
    box-shadow: 0px 0px 16px 0px #6B9BF2;
}

@media only screen  and (max-width: 768px) {
    .btnStyle{
        font-size: 16px;
        line-height: 20px;
    }
}
