.subTitle{
    margin-bottom: 110px;
    font-family: Roboto_Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #333663;
    text-align: center;
}

.packageWhole{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 233px;
    border-bottom:1px solid #8586A1;
    max-width: 1680px;
    width: 100%;
    margin-bottom: 48px;
}


@media only screen  and (max-width: 1440px) {
    .packageWhole{
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .packageWhole{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 50px;
        border-bottom:1px solid #8586A1;
        max-width: 1680px;
        width: 100%;
        margin-bottom: 48px;
    }
}

@media only screen  and (max-width: 768px) {
    .packageWhole{
        padding-bottom: 10px;
    }
}
