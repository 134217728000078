.homeStylying{
    margin-bottom: 100px;
}
.title{
    padding-top: 80px;
    margin-bottom: 24px;
    text-align: center;
    font-family: Roboto_Medium,sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 56px;
    color: #333663;
}

.bigTitle{
    font-family: Roboto_Bold,sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 64px;
    line-height: 75px;
    color: #1A1F6D;
    margin-bottom: 24px;
    text-align: center;
}

.title:nth-child(3){
    padding-top: 0;
}

.picturesDiv{
    display: flex;
    justify-content: space-between;
}

.imageStyle{
    max-width: 280px;
    width: 100%;
    height: 400px;
}

.first{
    padding-top: 80px;
}

.second{
    padding-top: 150px;
}

.third{
   padding-top: 80px;
}


.backgroundBlue{
    height: 80px;
    margin-top: -250px;
}

@media only screen  and (max-width: 1440px) {
    .picturesDiv{
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
    .first{
        padding-top: 0;
    }

    .second{
        padding-top:0;
    }

    .third{
        padding-top: 0;
    }
}

@media only screen  and (max-width: 768px) {
    .title{
        padding-top: 40px;
        font-size: 34px;
        line-height: 48px;
        margin-bottom: 12px;
    }

    .bigTitle{
        font-size: 48px;
        line-height: 52px;
        margin-bottom: 12px;
    }
}


