.activatePackageWhole{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 233px;
    border-bottom:1px solid #8586A1;
    max-width: 1680px;
    width: 100%;
    margin-bottom: 48px;
}

.activatePackageForm{
    width: 100%;
    display: flex;
    justify-content: center;
}

.subTitle{
    font-family: Roboto_Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #333663;
    margin-bottom: 114px;
}

.paymentWhole{
    display: flex;
    justify-content: space-between;
    max-width: 1153px;
    width: 100%;
}

.paymentLeft{
    display: flex;
    flex-direction: column;
    max-width: 544px;
    width: 100%;
}

.paymentTitle{
    font-family: Roboto_Medium,sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #333663;
    padding-bottom: 16px;
    border-bottom: 1px solid #868797;
    margin-bottom: 16px;
}

.paymentRight{
    display: flex;
    flex-direction: column;
    max-width: 544px;
    width: 100%;
}

.belowDiv{
    display: flex;
    align-items: center;
}

.cards{
    display: flex;
    align-items: center;
    margin-bottom: 28px;
    margin-top: 27px;
}

.radioLabelCards{
    display: flex;
    align-items: center;

}

.radioInput{
    margin-right: 10px;
    width: 16px;
    height: 16px
}


@media only screen  and (max-width: 1200px) {
    .paymentWhole{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .paymentRight{
        margin-top: 30px;
    }
    .activatePackageWhole{
        padding-bottom: 50px;
    }

    .subTitle{
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 30px;
        text-align: center;
    }
}

@media only screen  and (max-width: 500px) {
    .belowDiv{
        flex-direction: column;
        align-items: center;
    }
}