* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

body{
  min-height: 100vh;
}

html{
  scroll-behavior: smooth;
}

.container{
  max-width: 1680px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
  z-index: 10;
}

.inside{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload__image-wrapper{
  max-width: 828px;
  /*max-width:max-content;*/
  width: 100%;
  height: 621px;
  border: 1px dashed #8586A1;
  border-radius: 8px;
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #8586A1;
  position: relative;
}


.fullSize{
  max-width:max-content;
  border: none;
}


.image-item{
  max-width: 828px;
  width: 100%;
  height: 621px;
}

.uploadedImage{
  /*max-width: 828px;*/
  max-width: max-content;
  width: 100%;
  height: 621px;
  object-fit:contain
}

.deleteButton{
  font-size: 25px;
  background-color: #FFFFFF;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0;
}

.title{
  margin-top: 120px;
  font-family: Roboto_Medium,sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 56px;
  color: #1A1F6D;
  margin-bottom: 32px;
}

.buttonsDiv{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;
  max-width: 1680px;
  width: 100%;
}

.btnNext{
  width: 260px;
  padding: 16px 111px;
  background: #1A1F6D;
  border-radius: 4px;
  font-family: Roboto_Medium,sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #FFFFFF;
  cursor: pointer;
}

.backButton{
  font-family: Roboto_Medium,sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #868797;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.backSign{
  margin-right: 17px;
}

.checkbox-wrapper input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  width: 1.6em;
  height: 1.6em;
  border-radius: 0.15em;
  margin-right: 0.5em;
  border: 0.15em solid #007a7e;
  outline: none;
  cursor: pointer;
}

.btnNext:disabled{
  background-color: #868797;
  border: none;
}

@media only screen  and (max-width: 768px) {

  .title{
    margin-top: 60px;
    font-size: 34px;
    line-height: 51px;
    margin-bottom: 12px;
    text-align: center;
  }

  .uploadedImage{
    height: auto;
  }

  .fullSize{
    height: auto;
  }

  .image-item{
    height: auto;
  }

  .btnNext{
    font-size: 16px;
    line-height: 20px;
    max-width: 200px;
    width: 100%;
    padding: 16px 16px;
    text-align: center;
  }

  .backButton{
    font-size: 16px;
    line-height: 20px;
    margin-right: 25px;
  }

  .buttonsDiv{
    margin-bottom: 24px;
  }
}



@font-face {
  font-family: Roboto_Bold;
  src: url(app/assets/fonts/Roboto/Roboto_Bold.ttf);
}

@font-face {
  font-family: Roboto_Medium;
  src: url(app/assets/fonts/Roboto/Roboto_Medium.ttf);
}

@font-face {
  font-family: Roboto_Regular;
  src: url(app/assets/fonts/Roboto/Roboto_Regular.ttf);
}

@font-face {
  font-family: Roboto_Light;
  src: url(app/assets/fonts/Roboto/Roboto_Light.ttf);
}

@font-face {
  font-family: Roboto_Italic;
  src: url(app/assets/fonts/Roboto/Roboto_Italic.ttf);
}

@font-face {
  font-family: Roboto_Thin;
  src: url(app/assets/fonts/Roboto/Roboto_Thin.ttf);
}