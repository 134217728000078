.footerWhole{
    background-color:  #1A1F6D;
    padding-top: 24px;
    padding-bottom: 24px;
    text-align: center;
    font-family: Roboto_Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color:#8586A1;
}
