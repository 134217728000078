.paymentDoneWhole{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 160px;
    max-width: 1680px;
    width: 100%;
}

.subTitle{
    font-family: Roboto_Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #333663;
    margin-bottom: 72px;
}
.inputDiv{
    position: relative;
    max-width: 639px;
    width: 100%;
}
.linkInput{
    max-width: 639px;
    width: 100%;
    padding: 12px;
    font-family: Roboto_Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #1A1F6D;
}

.copyImg{
    position: absolute;
    cursor: pointer;
    top:20%;
    right:5%;
}


@media only screen  and (max-width: 768px) {
    .paymentDoneWhole{
        padding-bottom: 50px;
    }
    .subTitle{
        text-align: center;
        font-size: 22px;
        line-height: 32px;
        margin-bottom: 30px;
    }
}

