.secondPart{
    background: #1A1F6D;
    margin-top: 200px;
}

.picturesDiv{
    display: flex;
    margin-top: -150px;
    justify-content: space-between;
    margin-bottom: 120px;
}

.smallImage{
    max-width: 260px;
    width: 100%;
    height: 260px;
}

.title {
    font-family: Roboto_Medium, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
    color: #FFFFFF;
    margin-bottom: 100px;
}

@media only screen  and (max-width: 1900px) {
    .picturesDiv{
        flex-wrap: wrap;
        justify-content: space-evenly;
        margin-bottom: 50px;
    }
}


@media only screen  and (max-width: 768px) {
    .title {
        font-size: 34px;
        line-height: 51px;
        margin-bottom: 50px;
        text-align: center;
    }
}