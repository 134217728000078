.headerWhole{
    background-color:  #1A1F6D;
    padding-top: 24px;
    padding-bottom: 24px;
    text-align: center;
    font-family: Roboto_Bold, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 28px;
    color:#8586A1;
    cursor: pointer;
}