.control{
    margin-bottom: 32px;
    width: 100%;
}

/*.control input,*/
.control label {
    display: block;
    font-family: Roboto_Medium,sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #1A1F6D;
    margin-bottom: 7px;
}

.inputDiv{
    position: relative;
}

.control input,
.select {
    padding:12px;
    font-family: Roboto-Regular,sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #1A1F6D;
    background: #FFFFFF;
    border: 1px solid #868797;
    border-radius: 4px;
    /*max-width: 505px;*/
    width: 100%;
}

.control input:focus{
    outline: none;
    /*color:  #16145F;*/
}

.control input::placeholder{
    color: #868797;
}

.invalid{
    border: 2px solid rgba(249, 63, 63, 1) !important;
}

.textDanger {
    font-family: Inter-Regular, sans-serif;
    font-size: 16px;
    /*line-height: 24px;*/
    color: rgba(249, 63, 63, 1);
    margin-top: 4px;
    width: 505px;
}

.inputDiv img{
    width: 25px;
    position: absolute;
    bottom: 11px;
    right: 35px;
    cursor:pointer;
}

.redOne{
    color: #FF2B2B;
}

